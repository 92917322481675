<template>
  <div id="cctv-view">
    <div class="content-box">
      <div class="map-list">
        <button class="btn-cctv" @click="goCctvList">
          <img src="../assets/camera.png" /><span>CCTV 整合</span>
        </button>

        <img
          @click="goStation(stationInfo.key)"
          class="preview-dot"
          :src="require('../assets/' + stationInfo.key + '-dot.png')"
          v-for="stationInfo in stationInfoList"
          :key="stationInfo.key"
          :class="{ active: stationInfo.key === $route.params.key }"
        />
      </div>
      <video controls="controls" class="demo-video" id="player" muted></video>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import flvjs from 'flv.js'
// import { mixinWebsocket } from '@/utils/ws-cctv'
export default {
  mixins: [],
  data() {
    return {
      player: null,
      loading: true,
      ws: null,
    }
  },
  created() {
    this.ws = new WebSocket() // 建立連線
  },
  mounted() {
    this.$nextTick(() => {
      this.playVideo()
    })
  },
  computed: {
    ...mapState(['isLogin', 'stationInfoList']),
    spotKey() {
      if (this.$route.params.key !== 'nt') {
        return this.$route.params.key.toUpperCase()
      } else {
        return 'NORTH'
      }
    },
    cameraId() {
      return this.$route.params.id
    },
  },
  methods: {
    playVideo() {
      console.log('playVideo', flvjs.isSupported())
      const time1 = new Date().getTime()
      if (flvjs.isSupported()) {
        let video = document.getElementById('player')
        if (video) {
          this.player = flvjs.createPlayer(
            {
              type: 'flv',
              isLive: true,
              url:
                'wss://tycg-backend.skysharp.com.tw/ad-machine?groupId=' +
                this.spotKey +
                '&cameraId=' +
                this.cameraId,
              hasAudio: false,
            },
            {
              autoCleanupSourceBuffer: true,
              enableWorker: false, //不启用分离线程
              enableStashBuffer: true, //关闭IO隐藏缓冲区
              isLive: true,
              lazyLoad: false,
              deferLoadAfterSourceOpen: false,
            }
          )
          console.log('video', video)
          this.player.attachMediaElement(video)
          try {
            this.player.load()
            // let controller = this.player._transmuxer._controller
            // let wsLoader = controller._ioctl._loader
            // var oldWsOnCompleteFunc = wsLoader._onComplete
            // wsLoader._onComplete = function () {
            //   if (!controller._remuxer) {
            //     controller._remuxer = {
            //       flushStashedSamples: function () {
            //         // _this.loadingVisiable = false
            //         console.log('flushStashedSamples')
            //       },
            //     }
            //   }
            //   oldWsOnCompleteFunc()
            // }
            this.player.play().then(() => {
              console.log(new Date().getTime() - time1)
              this.loading = false
            })
            this.listenVideo()
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    listenVideo() {
      this.player.on(
        flvjs.Events.ERROR,
        (errorType, errorDetail, errorInfo) => {
          console.log('errorType', errorType)
          console.log('errorDetail', errorDetail)
          console.log('errorInfo', errorInfo)
          // 视频出错后销毁重建
          if (flvjs.isSupported()) {
            this.destroyFlv()
            this.playVideo()
          }
        }
      )
      // 视频断流
      // this.player.on('statistics_info', function (res) {
      //   if (this.lastDecodedFrames === 0) {
      //     this.lastDecodedFrames = res.decodedFrames
      //     return
      //   }
      //   if (this.lastDecodedFrames != res.decodedFrames) {
      //     this.lastDecodedFrames = res.decodedFrames
      //   } else {
      //     this.lastDecodedFrames = 0
      //     if (flvjs.isSupported()) {
      //       this.destroyFlv()
      //       this.playVideo()
      //     }
      //   }
      // })
    },

    destroyFlv() {
      if (this.player) {
        this.player.pause()
        this.player.unload()
        this.player.detachMediaElement()
        this.player.destroy()
        this.player = null
      }
    },
    goCctvList() {
      this.player = null
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
    goStation(key) {
      if (!this.isLogin) return
      console.log(' goStation key', key)
      this.player = null
      this.$router.replace({ name: 'Map', params: { key: key } })
    },
  },
  beforeDestroy() {
    console.log('cctv beforeDestroy')
    // this.destroyFlv()
  },
  destroy() {},
}
</script>

<style></style>
